/////
// Pack com frontend exclusivo do Heimdall, por exemplo:
//   - especializações e customizações do tema Inspinia
//   - componentes exclusivos para uso no projeto
///////

// These polyfills ensure that modern JS features (promises, async/await, arrow function
// expressions, and more) are available on the browsers you intend to support
import 'core-js/stable'
import 'regenerator-runtime/runtime'

// Adiciona todos os arquivos JS criados modificar o comportamento padrão da aplicação
// com objetivo de customizar o layout para o tema Heimdall
import '../heimdall';

// Adiciona todos os stylesheets
require("../heimdall/stylesheets")
